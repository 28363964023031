<template>
    <div>
        <v-text-field v-model="customerId" label="Customer ID" outlined></v-text-field>
        <v-text-field v-model="confirmationNumber" label="Confirmation #" outlined></v-text-field>
        <v-text-field v-model="orderId" label="Order ID" outlined></v-text-field>
        <v-text-field v-model="orderDate" label="Order Date" outlined></v-text-field>

        <v-row>
            <v-col cols="6">
                <v-btn @click="createOrder()" :loading="loading">Create Order</v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn @click="createSubscription()" :loading="loading">Create Subscription</v-btn>
            </v-col>
        </v-row>

        <br><br><br>

        <v-btn @click="athmovilAjax()" :loading="loading">ATH Ajax test</v-btn>
    </div>
</template>

<script>
import { PetuSubscription } from '@/classes/subscription';
import { PetuOrder } from "@/classes/order";
import { OrderStatus, SubscriptionFrequency } from '@/models';
import moment from 'moment';
import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            customerId: '',
            confirmationNumber: '',
            orderId: '',
            orderDate: ''
        }
    },
    methods: {
        async doit() {
            // await this.createOrder();
            await this.createSubscription();
        },
        async createOrder() {
            this.loading = true;
            try {
                let order = new PetuOrder();
                order.data.status                = OrderStatus.PROCESSING;
                order.data.customerID            = this.customerId;
                order.data.products              = [
                    { 
                        productId     : '12487d7e-0abe-4ebe-9625-cdd073a69499', 
                        qty           : 1,
                        price         : 120.00,
                        tax           : 13.80,
                        total         : 133.80,
                        isTaxable     : true,
                        name          : 'Petu Training - 3 días',
                        type          : 'Virtual',
                        trainingType  : 'Presencial',
                        dimensions    : {length: 0, width: 0, height: 0},
                        weight        : 0,
                        images        : []
                    }
                ];
                order.data.subtotal              = 120.00;
                order.data.discounts             = 0.00;
                order.data.shipping              = 0.00;
                order.data.tax                   = 13.80;
                order.data.total                 = 133.80;
                order.data.coupons               = [];
                order.data.paymentMethod         = 'athmovil';
                order.data.paymentConfirmation   = this.confirmationNumber;
                order.data.fromSubscriptionID    = '';

                let o = await order.save();
                console.log(order.data);

                this.loading = false;
            }
            catch(error) {
                console.error(error);
                this.loading = false;
            }
        },
        async createSubscription() {
            this.loading = true; 
            try {
                let s = new PetuSubscription();
                s.data.autoRenew = false;
                s.data.nextPaymentDate = moment( this.orderDate ).add( 1, 'M' );
                s.data.productID = '12487d7e-0abe-4ebe-9625-cdd073a69499';
                s.data.customerID = this.customerId;
                s.data.orderID = this.orderId;
                s.data.remainingSessions = 12;

                let r = await s.save();
                console.log(r);
                
                this.loading = false;
                this.customerId = this.confirmationNumber = this.orderId = this.orderDate = '';
            }
            catch(error) {
                console.error(error);
                this.loading = false;
            }
        },
        async athmovilAjax() {
            this.loading = true;

            try {
                let headersList = {
                    "Accept": "*/*",
                    "Content": "application/json",
                    "Content-Type": "application/json" ,
                    "Access-Control-Allow-Origin": "*"
                }

                let response = await axios.post('https://www.athmovil.com/rs/v3/searchTransaction', {
                    publicToken: "d8924b78d863e6fd917a9350ef6a63666cad1d74",
                    privateToken: "9tl7ktepaok/p4vzgv8znmkt27jaah4g0eqmghu1",
                    phoneNumber: "(939) 579-4020"
                }, { headers: headersList });

                console.log(response);

                this.loading = false;
            }
            catch(error) {
                console.error(error);
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
